import React from "react";
import classNames from "classnames";

interface IProps {
    className?: string;
    bio: string;
    // company?: string;
    // position?: string;
}

const UserBio = React.memo(({ className, bio }: IProps) => {
    return (
        <div className="mol-detail-header__bio">
            {bio && bio.length > 0 && (
                <div
                    className={classNames(
                        "mol-detail-header__bio-description",
                        // (!position && !company) ||
                        // (!position?.length &&
                        //     !company?.length &&
                        //     "mol-detail-header__bio-description--top-offset")
                    )}
                >
                    {bio}
                </div>
            )}
        </div>
    );
});

export default UserBio;
