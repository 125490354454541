import React from "react";
import classNames from "classnames";

interface IProps {
    className?: string;
    company?: string;
    position?: string;
}

const UserPosition = React.memo(({ className, company, position }: IProps) => {
    return (
        <div className={classNames("mol-detail-header__bio-position", className)}>
            {position}
            {company && company.length > 0
                ? `${position && position.length > 0 ? ` \u2013 ` : ""
                }${company}`
                : ""}
        </div>
    );
});

export default UserPosition;
