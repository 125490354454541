import { LoadingButton } from "@mui/lab";
import { TRANSLATIONS } from "locale/default";
import React, {useEffect, useCallback} from "react";
import { useForm } from "react-hook-form";
import { LocalizationType } from "ui/organism/App/state";
import {axiosPost} from "utils/axiosPostHook";
import classNames from "classnames";
import Modal from 'react-bootstrap/Modal';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface IProps {
  localization: LocalizationType;
  onClose(): void;
  fullname: string;
  pageId: string;
  tintColor: string;
}

export default function ShareContactForm({ onClose, localization, fullname, pageId, tintColor }: IProps) {
  const { register, handleSubmit, setError, getValues, formState: { isValid, errors }, reset, clearErrors } = useForm();
  const [apiError, setApiError] = React.useState<null | string>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  // const {data, error: apiError, loading, post} = usePost();
  // const { response, loading, error } = useAxios({method: "post", url: "https://api.bond-card.com/addConnection", });
  const firstInputErrorKey = Object.keys(errors)[0];
  // const firstInputErrorMessage = firstInputErrorKey && errors[firstInputErrorKey]?.message;
  // const targetRef = useRef<HTMLDivElement>(null);

  const sendConnectionData = async () => {
    const { name, phone, email, note } = getValues();

    if (!name.length) {
      setError('name', { type: "error", message: TRANSLATIONS.MODAL.SHARE_CONNECTION.ERROR.NAME[localization] });
    }

    if (!email.length && !phone.length) {
      setError('phone', { type: "error", message: TRANSLATIONS.MODAL.SHARE_CONNECTION.ERROR.PHONE[localization] });
      return;
    }

    if (errors.name) {
      return;
    }

    setLoading(true);
    const { error, loaded } = await axiosPost(
      "https://api.bond-card.com/addConnection",
      {
        connection: {
          targetPageId: pageId,
          name,
          phone,
          email,
          note,
        }
      }
    );

    if (loaded) {
      setLoading(false);
    }

    if (!loaded || error) {
      setLoading(false);
      setApiError(TRANSLATIONS.MODAL.SHARE_CONNECTION.SHARE_FAILED[localization]);
    }

    if (!error) {
      setLoading(false);
      // close modal
      onClose();
    }
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    setApiError(null);
    sendConnectionData();
  };

  const onInputFocus = useCallback(() => {
    if (window.visualViewport) {
      window.visualViewport.dispatchEvent(new Event('resize'));
    }

    window.dispatchEvent(new Event('resize'));
  }, []);

  const handleUserKeyPress = useCallback(event => {
    const { keyCode, code } = event;
    if (code === "Enter" || keyCode === 13) {
      event.preventDefault();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleUserKeyPress);
    // disableBodyScroll(targetRef.current);
    return () => {
      window.removeEventListener("keydown", handleUserKeyPress);
      // enableBodyScroll(targetRef.current);
      // clearAllBodyScrollLocks();
    };
  }, [handleUserKeyPress]);

  return (
    <>
      <Modal.Body style={{ height: `100vh` }}>
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <h2 className="mol-share-contact-form__title">{TRANSLATIONS.MODAL.SHARE_CONNECTION.TITLE[localization]} {fullname}</h2>
          <input
            // className={classNames(errors.name && typeof errors.name.message === "string" ? "mol-share-contact-form__input--clear" : "mol-share-contact-form__input--offset")}
            className="mol-share-contact-form__input--offset mol-share-contact-form__input--name"
            id="name"
            {...register("name", {
              required: TRANSLATIONS.MODAL.SHARE_CONNECTION.ERROR.NAME[localization],
              onChange: () => {
                if (errors.name) {
                  clearErrors("name");
                }
              }
            })}
            placeholder={TRANSLATIONS.MODAL.SHARE_CONNECTION.NAME[localization]}
            type="text"
            onFocus={onInputFocus}
          />
          {errors.name && typeof errors.name.message === "string" && <span className="mol-share-contact-form__error">{errors.name.message}</span>}
          <input
            className="mol-share-contact-form__input--offset"
            id="phone"
            {...register("phone", {
              onChange: () => {
                if (errors.phone || errors.email) {
                  clearErrors("email");
                  clearErrors("phone");
                }
              }
            })}
            placeholder={TRANSLATIONS.MODAL.SHARE_CONNECTION.PHONE[localization]}
            type="tel"
            onFocus={onInputFocus}
          />
          {!errors.name && errors.phone && typeof errors.phone.message === "string" && <span className="mol-share-contact-form__error">{errors.phone.message}</span>}
          <input
            // className={classNames(errors.email && typeof errors.email.message === "string" ? "mol-share-contact-form__input--clear" : "mol-share-contact-form__input--offset")}
            className="mol-share-contact-form__input--email mol-share-contact-form__input--offset"
            id="email"
            {...register("email", {
              // required: "required",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format"
              },
              onChange: () => {
                const isEmailRe = new RegExp(/\S+@\S+\.\S+/);
                if (!isEmailRe.test(getValues("email"))) {
                  clearErrors("phone");
                  setError("email", { type: "manual", message: TRANSLATIONS.MODAL.SHARE_CONNECTION.ERROR.EMAIL[localization] })
                  return;
                }

                if (errors.phone || errors.email) {
                  clearErrors("email");
                  clearErrors("phone");
                }
              }
              // validate: (value) => {
              //   console.log("validate", value);
              //   // clearErrors("phone")
              //   return;
              // },
            })}
            placeholder={TRANSLATIONS.MODAL.SHARE_CONNECTION.EMAIL[localization]}
            type="email"
            onFocus={onInputFocus}
          />
          {!errors.name && errors.email && typeof errors.email.message === "string" && <span className="mol-share-contact-form__error">{errors.email.message}</span>}
          {/* {errors.email && typeof errors.email.message === "string" && <span className="input__error">{errors.email.message}</span>} */}
          {/* <input
        className="mol-share-contact-form__input--offset"
        id="company"
        {...register("company")}
        placeholder={TRANSLATIONS.MODAL.SHARE_CONNECTION.NOTES[localization]}
        type="text"
      /> */}
          <textarea
            // className="mol-share-contact-form__input--offset mol-share-contact-form__notes"
            id="note"
            {...register("note")}
            placeholder={TRANSLATIONS.MODAL.SHARE_CONNECTION.NOTES[localization]}
            rows={4}
            onFocus={onInputFocus}
          />
           <div className={classNames("mol-share-contact-form__error", apiError && "mol-share-contact-form__error--visible")}>{apiError}</div>
        </form>
      </Modal.Body >
      <Modal.Footer style={{ justifyContent: "center", borderTop: "none", padding: "10px 0 0 0", zIndex: 10000 }}>
        <LoadingButton
          loading={loading}
          loadingPosition="center"
          variant="contained"
          disableRipple
          disableElevation
          disableFocusRipple
          disableTouchRipple
          sx={
            {
              border: "none",
              borderRadius: "10px",
              width: "100%",
              height: "48px",

              background: tintColor,
              color: "#fff",

              fontWeight: 700,
              fontSize: "16px",
              lineHeight: "18px",
              boxShadow: "none",
              textTransform: "inherit",

              '&:hover': {
                background: tintColor,
                boxShadow: "none",
              }
            }
          }
          type="submit"
          onClick={onSubmit}
        >
          {TRANSLATIONS.MODAL.SHARE_CONNECTION.SEND[localization]}
        </LoadingButton>
        <div className="mol-share-contact-form__mini-label">{TRANSLATIONS.MODAL.SHARE_CONNECTION.MINI[localization]}</div>
      </Modal.Footer>
    </>
  );
}