import React, {useState, useCallback, useEffect, useRef} from 'react';
import ShareContactForm from '../ShareContactFormUI';
import { LocalizationType } from 'ui/organism/App/state';
import { Helmet } from "react-helmet";
import Modal from 'react-bootstrap/Modal';
// import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

interface IProps {
    fullname: string;
    pageId: string;
    localization: LocalizationType;
    isOpen: boolean;
    color: string;
    setOpen(isOpen: boolean): void;
    onModalOpen(open?: boolean): void;
}

export default function ConnectionModal({ isOpen, setOpen, onModalOpen, localization, fullname, pageId, color }: IProps) {
    const [height, setHeight] = useState<string>("100vh");
    // let previousHeight = 0;

    const handleClose = () => {
        onModalOpen(false);
        setOpen(false);
    };

    // Fixnutie spravnej vysky modalu po vysunuti virtual keyboard na telefone
    const onResize = useCallback(() => {
        const currentVisibleHeight = window.visualViewport?.height;
        setHeight(`${currentVisibleHeight}px`);
    }, []);

    const onScroll = useCallback((event: Event) => {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation()
    }, []);

    useEffect(() => {
        if (window.visualViewport) {
            window.visualViewport.addEventListener("resize", onResize);
            window.visualViewport.addEventListener("scroll", onScroll);
            window.visualViewport.dispatchEvent(new Event('resize'));
        } else {
            window.addEventListener("resize", onResize);
            // fix 100vh on modal open pretoze sa nefirne resize
            window.dispatchEvent(new Event('resize'));
        }
        return () => {
            if (window.visualViewport) {
                window.visualViewport.removeEventListener("resize", onResize);
            } else {
                window.removeEventListener("resize", onResize);
                window.addEventListener("scroll", onScroll);
            }
        };
    }, [onResize]);
    return (
        <>
            {/* <Modal style={{ height: `${getComputedStyle(document.documentElement).getPropertyValue('--viewport-visible-height')}` }} show={isOpen} onHide={handleClose} scrollable={true}> */}
            <Modal style={{ height: `${height}` }} show={isOpen} onHide={handleClose} scrollable={true}>
                <Helmet>
                    <title>PRO Digital Business Card - modal</title>
                    <meta name="theme-color" content="#262526" data-react-helmet="true" />
                </Helmet>
                <ShareContactForm pageId={pageId} fullname={fullname} localization={localization} tintColor={color} onClose={handleClose} />
            </Modal>
        </>
    );
}