import { useContext } from "react";
import { ToastContext } from ".";

export const useToast = () => {
	const { toast, show, hide } = useContext(ToastContext);

	return {
		toast,
		show,
		hide,
	};
};
