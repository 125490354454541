import React from "react";

interface IProps {
	country?: string;
	zip?: string;
	street?: string;
	city?: string;
	state?: string;
}

const AddressItem = React.memo(
	({ country, zip, street, city, state }: IProps) => {
		return (
			<div>
				{[street, zip, city, state, country].filter((item) => item).join(", ")}
			</div>
		);
	},
);

export default AddressItem;
