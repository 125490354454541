import classnames from "classnames";
import React from "react";

interface IProps {
	className?: string;
	coverPhoto: string;
	layout: "portrait" | "standard";
}

const CoverPhoto = React.memo(({ className, coverPhoto, layout }: IProps) => {
	return (
		<div
			className={classnames(
				layout === "standard"
					? "atm-cover-photo--standart"
					: "atm-cover-photo--portrait",
				className,
			)}
		>
			<img className="atm-cover-photo__image" src={coverPhoto} alt="cover" />
		</div>
	);
});

export default CoverPhoto;
