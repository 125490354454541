import classNames from "classnames";
import React from "react";
import type { LocalizationType } from "ui/organism/App/state";
import { isFacebookApp } from "utils/fbBroserDetector";
import { useSearchParams } from "react-router-dom";
import UserName from "../atom/UserName";
import UserBio from "../atom/UserBio";
import UserPosition from "../atom/UserPosition";
import CoverPhoto from "../atom/CoverPhoto";
import DetailBondLogo from "../atom/DetailBondLogo";
import SaveContactButton from "../atom/SaveContactButton";
import UserCompanyLogo from "../atom/UserCompanyLogo";
import placeholder from "../../assets/placeholder.jpg";

export interface IExternalProps {
	className?: string;
	isBondLogoHidden?: boolean;
	isSaveButtonHidden?: boolean;
	localization: LocalizationType;
	tintColor: string;
	file: {
		url: string;
		name: string;
	};
	user: {
		imageUrl?: string;
		lastName?: string;
		firstName?: string;
		bio?: string;
		position?: string;
		company?: string;
		logo?: string;
		hasProBadge?: boolean;
		coverPhoto?: string;
	};
	onItemSave(): Promise<void>;
}

type IProps = IExternalProps;

const LayoutPortrait = React.memo(
	({
		className,
		localization,
		tintColor,
		user,
		file,
		onItemSave,
		isBondLogoHidden,
		isSaveButtonHidden,
	}: IProps) => {
		const [searchParams] = useSearchParams();
		const platform = searchParams.get("platform");
		const isInAppBrowser =
			isFacebookApp() && (!platform || platform !== "bond");

		return (
			<div className={classNames("ogm-card-detail__header", className)}>
				<div>
					<CoverPhoto
						layout="portrait"
						coverPhoto={user.imageUrl || placeholder}
					/>
				</div>
				{!isBondLogoHidden && (
					<DetailBondLogo className="ogm-card-detail__app-link" />
				)}
				<div className="mol-layout-portrait__user-info">
					<div className="mol-layout-portrait__user-info--right">
						<UserName
							className="mol-layout-portrait__user-name"
							firstName={user.firstName}
							lastName={user.lastName}
							hasProBadge={user.hasProBadge}
						/>
						<div className="mol-layout-portrait__position">
							{((user.position && user.position.length > 0) ||
								(user.company && user.company.length > 0)) && (
								<UserPosition position={user.position} company={user.company} />
							)}
							{user.bio && user.bio.length > 0 && <UserBio bio={user.bio} />}
						</div>
					</div>
					{user.logo && (
						<UserCompanyLogo
							className="mol-layout-portrait__company-logo"
							companyUrl={user.logo}
						/>
					)}
				</div>
				{!isInAppBrowser && !isSaveButtonHidden && file.url ? (
					<SaveContactButton
						onItemSave={onItemSave}
						localization={localization}
						tintColor={tintColor}
						file={file}
					/>
				) : (
					<div className="mol-layout-portrait--save-hidden" />
				)}
			</div>
		);
	},
);

export default LayoutPortrait;
