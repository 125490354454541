import React from "react";
import { useToast } from "./hooks";

export const Toast = () => {
	const { toast, hide } = useToast();

	return (
		<button type="button" className="bond-toast" onClick={hide}>
			{toast?.message}
		</button>
	);
};
