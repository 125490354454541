import React from "react";

interface IProps {
  id: string;
  placeholder: string;
  value: string; // current value
  error: null | string;
  className?: string;
  onChange(value: string): void;
}

const PasswordField = React.memo(
  ({ id, error, placeholder, value, onChange, className }: IProps) => {
    return (
      <div className={className}>
        <input
          id={id}
          type="password"
          value={value}
          placeholder={placeholder}
          onChange={onValueChange}
        />
        {error && <span>{error}</span>}
      </div>
    );

    function onValueChange(event: React.ChangeEvent<HTMLInputElement>) {
      onChange(event.target.value);
    }
  }
);

export default PasswordField;
