import { createSelector, createStructuredSelector } from "reselect";
import { localizationSelector } from "../App/selectors";
import { IDataProps } from "./CardDetailUI";
import { NAMESPACE, IState, DEFAULT_STATE } from "./state";

// Used in clientRenderer
export const stateSelector = (globalState: unknown): IState =>
  (globalState && (globalState as any))[NAMESPACE] || DEFAULT_STATE;

const dataSelector = createSelector(
  stateSelector,
  (state: IState): any => state.data
);

const errorSelector = createSelector(
  stateSelector,
  (state: IState): null | Error => state.error
);

const isLoadingSelector = createSelector(
  stateSelector,
  (state: IState): boolean => state.isLoading
);

export default createStructuredSelector<{}, IDataProps>({
  data: dataSelector,
  isLoading: isLoadingSelector,
  error: errorSelector,
  localization: localizationSelector,
});
