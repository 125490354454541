import classnames from "classnames";
import React from "react";
import Icon from "./Icon";

interface IProps {
	className?: string;
	type: "text" | "icon";
}

const Logo = React.memo(({ className, type }: IProps) => {
	if (type === "text") {
		return <div className={classnames("atm-logo", className)}>Bond</div>;
	}

	return (
		<a
			target="_blank"
			rel="noreferrer"
			href="/get-app"
			className={classnames("atm-logo", className)}
		>
			{<Icon icon="logo" />}
		</a>
	);
});

export default Logo;
