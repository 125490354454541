import classnames from "classnames";
import React from "react";
import LanguageSelect from "ui/atom/LanguageSelect";
import Logo from "ui/atom/Logo";
import { LocalizationType } from "ui/organism/App/state";

export interface IDataProps {
	localization: LocalizationType;
}

export interface ICallbackProps {
	onLocalizationChange(localization: LocalizationType): void;
}

export interface IExternalProps {
	className?: string;
}

type IProps = IDataProps & ICallbackProps & IExternalProps;

const Header = React.memo(
	({ className, localization, onLocalizationChange }: IProps) => {
		return (
			<div className={classnames("mol-header", className)}>
				<Logo type="icon" />
				<LanguageSelect
					localization={localization}
					onLocalizationChange={onLocalizationChange}
				/>
			</div>
		);
	},
);

export default Header;
