import classnames from "classnames";
import React from "react";
import Icon from "./Icon";

interface IProps {
	className?: string;
	companyUrl: string;
}

const UserCompanyLogo = React.memo(({ className, companyUrl }: IProps) => {
	const [loaded, setLoaded] = React.useState(false);

	// on image loaded sa zavola iba v pripae uspesneho nacitania obrazku
	// error image example "https://ik.imagekit.io/demo/img/non_existent_image.jpg"
	const onImageLoaded = () => {
		setLoaded(true);
	};

	return (
		<div
			className={classnames(
				"atm-user-company-logo",
				!loaded && "atm-user-company-logo--hidden",
				className,
			)}
		>
			<img
				alt="logo"
				className="atm-user-company-logo__image"
				src={companyUrl}
				style={{ display: "inline-block" }}
				onLoad={onImageLoaded}
			/>
		</div>
	);
});

export default UserCompanyLogo;
