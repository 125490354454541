export const TRANSLATIONS = {
	DOCUMENT: {
		TITLE: {
			sk: "Digitálna vizitka",
			cz: "Digitální vizitka",
			en: "Reset password",
		},
	},
	GENERAL: {
		TOAST: {
			COPIED_TO_CLIPBOARD: {
				MESSAGE: {
					sk: "Text Copied!",
					cz: "Zkopírováno!",
					en: "Skopírované!",
				},
			},
		},
	},
	HOME: {
		TITLE: {
			sk: "Stiahnite si aplikáciu Bond",
			cz: "Stáhněte si aplikaci Bond",
			en: "Download the Bond app",
		},
		DESCRIPTION: {
			sk: "Naskenujte QR kód telefónom a stiahnute si aplikáciu Bond",
			cz: "Naskenujte QR kód telefonem a stáhněte si aplikaci Bond",
			en: "Scan the QR code with your phone's camera to download the Bond app",
		},
	},
	RESET_PASSWORD: {
		LABEL: {
			sk: "Reset hesla",
			cz: "Reset hesla",
			en: "Reset password",
		},
		NEW: {
			sk: "Nové heslo",
			cz: "Nové heslo",
			en: "New password",
		},
		NEW_AGAIN: {
			sk: "Nové heslo znovu",
			cz: "Nové heslo znovu",
			en: "New password again",
		},
		DONT_MATCH: {
			sk: "Heslá sa nezhodujú",
			cz: "Hesla se nezhodují",
			en: "Passwords do not match",
		},
		UPDATE: {
			sk: "Zmeniť heslo",
			cz: "Změnit heslo",
			en: "Change Password",
		},
		MIN_LENGTH: {
			sk: "Heslo musí obsahovať min. 8 znakov",
			cz: "Heslo musí obsahovat min. 8 znaků",
			en: "The password must contain min. 8 characters",
		},
		UPDATE_DONE: {
			sk: "Heslo bolo úspešne zmenené",
			cz: "Heslo bylo úspěšně změneno",
			en: "Password changed successfully",
		},
		UPDATE_FAILED: {
			sk: "Heslo sa nepodarilo zmeniť, skúste to znova",
			cz: "Heslo se nepodařilo změnit, zkuste to znovu",
			en: "The password could not be changed, please try again",
		},
	},
	DETAIL: {
		BUTTON: {
			sk: "Uložiť kontakt",
			cz: "Uložit kontakt",
			en: "Save Contact",
		},
	},
	BLANK_PAGE: {
		LABEL: {
			sk: "Tu nič nie je",
			cz: "Zde nic není",
			en: "There’s nothing here",
		},
	},
	NOT_ACTIVATED_PAGE: {
		LABEL: {
			sk: "Tento produkt nie je aktivovaný",
			cz: "Tento produkt není aktivovaný",
			en: "This product is not activated",
		},
		DESCRIPTION: {
			sk: "Stiahnite si aplikáciu Bond, prihláste sa a pripojte produkt k Vášmu účtu.",
			cz: "Stáhněte si aplikaci Bond, přihlašte se a připojte produkt k Vašemu účtu.",
			en: "Download the Bond app, sign in & link the product with your account.",
		},
		BUTTON: {
			sk: "Stiahnuť aplikáciu",
			cz: "Stáhnout aplikaci",
			en: "Download the app",
		},
	},
	MODAL: {
		SHARE_CONNECTION: {
			TITLE: {
				sk: "Zdieľajte svoj kontakt s",
				cz: "Sdílejte svůj kontakt s",
				en: "Share your contact with",
			},
			SHARE_FAILED: {
				sk: "Kontakt sa nepodarilo zdieľať, skúste to znova",
				cz: "Kontakt se nepodařilo sdílet, zkuste to znovu",
				en: "The contact could not be shared, please try again",
			},
			NAME: {
				sk: "Meno",
				cz: "Jméno",
				en: "Name",
			},
			PHONE: {
				sk: "Telefón",
				cz: "Telefón",
				en: "Phone",
			},
			EMAIL: {
				sk: "E-mail",
				cz: "E-mail",
				en: "E-mail",
			},
			MINI: {
				sk: "Bond nezdieľa ani nepredáva vaše údaje",
				cz: "Bond nesdílí ani neprodává vaše data",
				en: "Bond does not share or sell your data",
			},
			NOTES: {
				sk: "Poznámky",
				cz: "Poznámky",
				en: "Note",
			},
			SEND: {
				sk: "Zdieľať",
				cz: "Sdílet",
				en: "Share",
			},
			ERROR: {
				NAME: {
					sk: "Zadajte meno",
					cz: "Zadejte jméno",
					en: "Fill name",
				},
				PHONE: {
					sk: "Zadajte telefón alebo e-mail",
					cz: "Zadejte telefón nebo e-mail",
					en: "Fill phone or e-mail",
				},
				EMAIL: {
					sk: "Zadajte e-mail v správnom formáte",
					cz: "Zadejte e-mail ve správném formátu",
					en: "Enter e-mail in correct format",
				},
			},
		},
	},
	FOOTER: {
		ALL_RIGHTS: {
			sk: "Všetky práva vyhradené",
			cz: "Všechna práva vyhrazena",
			en: "All rights reserved",
		},
	},
};
