import React from "react";
import classNames from "classnames";
import Icon from "./Icon";

interface IProps {
    className?: string;
    firstName?: string;
    lastName?: string;
    hasProBadge?: boolean;
}

const UserName = React.memo(({ className, firstName, lastName, hasProBadge }: IProps) => {
    return (
        <div className={classNames("atm-user-avatar__username-wrapper", className)}>
            <div className="atm-user-avatar__username">
                {firstName || ""}{firstName?.length && lastName?.length && lastName.length > 0 ? " " : ""}{lastName || ""}
            </div>
            {hasProBadge && <Icon icon="verified" />}
        </div>
    );
});

export default UserName;
