import { createSelector, createStructuredSelector } from "reselect";
import { IDataProps } from "./AppUI";
import { NAMESPACE, IState, DEFAULT_STATE, LocalizationType } from "./state";

// Used in clientRenderer
export const stateSelector = (globalState: unknown): IState =>
  (globalState && (globalState as any))[NAMESPACE] || DEFAULT_STATE;

export const applicationVersionSelector = createSelector(
  [stateSelector],
  (state: IState): string => state.appVersion
);

export const localizationSelector = createSelector(
  stateSelector,
  (state: IState): LocalizationType => state.localization
);

export const uidSelector = createSelector(
  stateSelector,
  (state: IState):null | string => state.uid
);

export default createStructuredSelector<IState, IDataProps>({
  applicationVersion: applicationVersionSelector,
  localization: localizationSelector,
  uid: uidSelector,
  // Can add another selectors, applicationVersion have to be defined in IDataProps
});
