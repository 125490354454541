import classnames from "classnames";
import React from "react";
import {TRANSLATIONS} from "../../locale/default";

interface IProps {
    className?: string;
    tintColor: string;
    file: {
        url: string;
        name: string;
    };
    localization: string;
    onItemSave(): Promise<void>;
}

const SaveContactButton = React.memo(({ className, onItemSave, localization, tintColor, file }: IProps) => {
    return (
        <div className={classnames("atm-save-contact-button", className)}>
            <div
                className="atm-save-contact-button__action"
                style={{ background: tintColor }}
                onClick={onItemSave}
            >
                <a
                    className="atm-save-contact-button__action-link"
                    href={file.url}
                    target="_blank"
                    rel="noreferrer"
                    download={file.name}
                >
                    {TRANSLATIONS.DETAIL.BUTTON[localization]}
                </a>
            </div>
        </div>
    );
});

export default SaveContactButton;
