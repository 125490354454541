import React from "react";
import { Helmet } from "react-helmet";
import Icon, { AVAILABLE_ICONS } from "ui/atom/Icon";
import LanguageSelect from "ui/atom/LanguageSelect";
import type { LocalizationType } from "ui/organism/App/state";
import app_store_btn from "../../../assets/app_store_btn.png";
import google_store_btn from "../../../assets/google_store_btn.png";
import { TRANSLATIONS } from "../../../locale/default";

export interface IDataProps {
	localization: LocalizationType;
}

export interface ICallbackProps {
	onLocalizationChange(localization: LocalizationType): void;
}

export interface IExternalProps {
	className?: string;
}

type IProps = IDataProps & ICallbackProps & IExternalProps;

export const APP_STORE_URL =
	"https://apps.apple.com/cz/app/bond-digital-business-card/id1616006307";
export const PLAY_STORE_URL =
	"https://play.google.com/store/apps/details?id=com.bondcard.bond";

const HomePage = React.memo(
	({ localization, onLocalizationChange }: IProps) => {
		return (
			<div className="ogm-body">
				<div className="ogm-home__lang-select">
					<LanguageSelect
						localization={localization}
						onLocalizationChange={onLocalizationChange}
					/>
				</div>
				<div className="ogm-home">
					<Helmet>
						<meta
							name="theme-color"
							content="#fffefe"
							data-react-helmet="true"
						/>
					</Helmet>
					<div className="ogm-home__content">
						<h1>{TRANSLATIONS.HOME.TITLE[localization]}</h1>
						<a
							href={APP_STORE_URL}
							className="ogm-home__icon-wrapper"
							target="_blank"
							rel="noreferrer"
						>
							<Icon icon={AVAILABLE_ICONS.QR_CODE} className="ogm-home__icon" />
							<div className="ogm-home__icon-background" />
						</a>
						<p>{TRANSLATIONS.HOME.DESCRIPTION[localization]}</p>
						<a
							href={PLAY_STORE_URL}
							className="ogm-home__icon-store ogm-home__icon--offset"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="ogm-home__icon "
								src={google_store_btn}
								alt="Google Play"
							/>
						</a>
						<a
							href={APP_STORE_URL}
							className="ogm-home__icon-store"
							target="_blank"
							rel="noreferrer"
						>
							<img
								className="ogm-home__icon"
								src={app_store_btn}
								alt="App Store"
							/>
						</a>
					</div>
				</div>
			</div>
		);
	},
);

export default HomePage;
