import { FluxStandardAction } from "flux-standard-action";
import { LocalizationType } from "./state";

const Action = {
  INIT_APP_VERSION: "application.INIT_APP_VERSION",
  SET_LOCALIZATION: "application.SET_LOCALIZATION",
  SET_UID: "application.SET_UID",
  SET_LOCAL_STORAGE_LOCALIZATION: "application.SET_LOCAL_STORAGE_LOCALIZATION",

  initAppVersion(version: string): FluxStandardAction<string, string> {
    return {
      payload: version,
      type: Action.INIT_APP_VERSION,
    };
  },

  setLocalization(
    language: LocalizationType
  ): FluxStandardAction<string, LocalizationType> {
    return {
      payload: language,
      type: Action.SET_LOCALIZATION,
    };
  },

  setUID(
    uid: string
  ): FluxStandardAction<string, string> {
    return {
      payload: uid,
      type: Action.SET_UID,
    };
  },

  setLocalStorageLocalization(
    language: LocalizationType
  ): FluxStandardAction<string, LocalizationType> {
    return {
      payload: language,
      type: Action.SET_LOCAL_STORAGE_LOCALIZATION,
    };
  },
};

export default Action;
