import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import DetailItem from "ui/atom/DetailItem";
import { useParams, useSearchParams } from "react-router-dom";
// import useFetch from "react-fetch-hook";
// import ActionButton from "ui/atom/ActionButton";
import Loader from "ui/atom/Loader";
import ErrorPage from "ui/molecule/errorPage/ErrorPage";
import NotActivatedPage from "ui/molecule/notActivatedPage/notActivatedPage";
import type { LocalizationType } from "../App/state";
import { axiosGet, axiosPost } from "utils/axiosPostHook";
import { logEvent } from "firebase/analytics";
import analytics, { ANALYTICS_EVENTS } from "app/analytics";
import DetailHeader from "ui/molecule/detailHeader/DetailHeaderUI";
import ErrorBoundary from "ui/atom/ErrorBoundary";
import { getUserAgent } from "utils/userAgent";
import type { UAParserInstance } from "ua-parser-js";
// import ConnectionModal from "ui/molecule/connectionModal/ConnectionModalUI";
import ConnectionModal from "ui/molecule/connectionModal/ConnectionModalBootUI";
import Cookies from "js-cookie";
import { Helmet } from "react-helmet";
import LayoutPortrait from "../../molecule/LayoutPortrait";

const DEFAULT_ICON_COLOR = "#285FF5";
const HTML_ELEM_MODAL_OPEN = "ogm-html__modal--open";
const HTML_ELEM_MODAL_CLOSE = "ogm-html__modal--close";

export interface IDataProps {
	isLoading: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	data: null | any;
	error: null | Error;
	localization: LocalizationType;
}

export interface IExternalProps {
	uid: null | string;
}

export interface ICallbackProps {
	onFetchData(id: string): void;
}

type Props = IDataProps & IExternalProps;

const CardDetailUI = React.memo(({ localization, uid }: Props) => {
	const { id } = useParams();
	const [searchParams] = useSearchParams();
	const [isOpen, setOpen] = React.useState(false);
	const [hitSend, setHitSend] = React.useState(false);
	const [itemClicked, setItemClicked] = React.useState(false);
	const [saveButtonClicked, setSaveButtonClicked] = React.useState(false);
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const [state, setState] = useState<{
		error: any;
		data: any;
		isLoading: any;
		status?: any;
	}>({ error: null, data: null, isLoading: true, status: null });
	const UAParser: null | UAParserInstance = getUserAgent();

	const pageParam = searchParams.get("page");
	const isPageRequested = useMemo(() => pageParam === "true", [pageParam]);

	useEffect(() => {
		// https://api.bond-card.com/detail/d6a0e1a9-9729-496f-b952-f9c6ffb386b7
		// http://localhost:3000/p1/jGTB8WyVg - huskoca RICH PROFILE
		// http://localhost:3000/p1/IRvXonlVg - ERROR uri
		// http://localhost:3000/mXpkwQEVR - MILAN
		onDetailFetch(id);
		// const parser = getUserAgent();

		logEvent(analytics, ANALYTICS_EVENTS.CARD_DETAIL);
	}, [id]);

	const data = useMemo(() => {
		if (isPageRequested) {
			return state.data?.page;
		}
		return state.data;
	}, [isPageRequested, state.data]);

	const onModalOpen = (open?: boolean) => {
		const htmlElem = document.getElementsByTagName("html")[0];
		if (htmlElem.classList.contains(HTML_ELEM_MODAL_OPEN)) {
			htmlElem.classList.remove(HTML_ELEM_MODAL_OPEN);
		}

		if (htmlElem.classList.contains(HTML_ELEM_MODAL_CLOSE)) {
			htmlElem.classList.remove(HTML_ELEM_MODAL_CLOSE);
		}
		htmlElem.classList.add(open ? HTML_ELEM_MODAL_OPEN : HTML_ELEM_MODAL_CLOSE);
	};

	if (state?.status === 404) {
		return <NotActivatedPage />;
	}

	if (state.error) {
		return <ErrorPage />;
	}

	if (state.isLoading) {
		return <Loader />;
	}

	if (!data) {
		return null;
	}

	if (!state.error && data?.id && !hitSend) {
		onLog({
			pageId: data.id,
			deviceId: `${uid}`,
			type: "view",
		});
		setHitSend(true);
	}

	const { link, filename } = createVCFDownloadLink(data);
	const isOnlyHeader =
		data.items.find((item) => item.type === "header") &&
		data.items.filter((item) => item.enabled).length === 1;
	const { tint } = data;
	const tintColor = (tint?.length && tint) || DEFAULT_ICON_COLOR;
	const header = data.items?.find((item) => item.type === "header");
	const firstName = header?.values.find(
		(val) => val.name === "firstname",
	)?.value;
	const lastName = header?.values.find((val) => val.name === "lastname")?.value;

	return (
		<div
			className={classNames(
				"ogm-card-detail__wrapper",
				!isOnlyHeader && "ogm-card-detail__wrapper--padded",
			)}
		>
			<Helmet>
				<title>
					PRO Digital Business Card - {[firstName, lastName].join(" ")}
				</title>
				<meta name="theme-color" content="#fffefe" data-react-helmet="true" />
			</Helmet>
			{data.items.map((item) => {
				switch (item.type) {
					case "header": {
						const firstName = item.values.find(
							(val) => val.name === "firstname",
						)?.value;
						const lastName = item.values.find(
							(val) => val.name === "lastname",
						)?.value;
						const bio = item.values.find((val) => val.name === "bio");
						const position = item.values.find((val) => val.name === "position");
						const logo = item.values.find((val) => val.name === "logo");
						const company = item.values.find((val) => val.name === "company");
						const coverPhoto = item.values.find((val) => val.name === "cover");

						return (
							<div key={item.type}>
								<ErrorBoundary>
									{/*{data?.style === "portrait" ? (*/}
									{data?.style === "portrait" ? (
										<LayoutPortrait
											localization={localization}
											tintColor={tintColor}
											file={{
												url: link,
												name: filename,
											}}
											user={{
												imageUrl: item?.picture,
												lastName,
												firstName,
												bio: bio?.value,
												position: position?.value,
												company: company?.value,
												logo: logo?.value,
												hasProBadge: !!data.hasProBadge,
												coverPhoto: coverPhoto?.value,
											}}
											isBondLogoHidden={data.isBondLogoHidden}
											isSaveButtonHidden={data.isSaveButtonHidden}
											onItemSave={onItemSave}
										/>
									) : (
										<DetailHeader
											localization={localization}
											tintColor={tintColor}
											file={{
												url: link,
												name: filename,
											}}
											user={{
												imageUrl: item?.picture,
												lastName,
												firstName,
												bio: bio?.value,
												position: position?.value,
												company: company?.value,
												logo: logo?.value,
												hasProBadge: !!data.hasProBadge,
												coverPhoto: coverPhoto?.value,
											}}
											isBondLogoHidden={data.isBondLogoHidden}
											isSaveButtonHidden={data.isSaveButtonHidden}
											onItemSave={onItemSave}
										/>
									)}
								</ErrorBoundary>
							</div>
						);
					}
					default:
						return null;
				}
			})}
			<div className="ogm-card-detail__items">
				{data.items
					.filter((item) => item.enabled)
					.map((item, index) => {
						switch (item.type) {
							case "phone":
							case "email":
							case "link":
							case "address":
							case "note":
							case "socialProfile":
							case "social":
							case "text":
							case "textHeader":
							case "image":
							case "spacer":
							case "youTubeVideo":
								return (
									<ErrorBoundary key={`${item.type}-${index}`}>
										<DetailItem
											index={index}
											detailId={data.id}
											item={item}
											color={tintColor}
											uid={uid}
											UAParser={UAParser}
											itemClicked={{ is: itemClicked, set: setItemClicked }}
										/>
									</ErrorBoundary>
								);
							default:
								return null;
						}
					})}
			</div>
			<ConnectionModal
				pageId={data.id}
				fullname={`${firstName || ""}${lastName?.length ? " " : ""}${
					lastName || ""
				}`}
				localization={localization}
				isOpen={isOpen}
				color={tintColor}
				setOpen={setOpen}
				onModalOpen={onModalOpen}
			/>
		</div>
	);

	async function onItemSave(): Promise<void> {
		try {
			if (!saveButtonClicked) {
				await axiosPost("https://api.bond-card.com/log", {
					log: {
						pageId: data.id,
						deviceId: `${uid}`,
						type: "save", // view save click
					},
				});
			}

			setSaveButtonClicked(true);

			if (!data.isCaptureEnabled) {
				return;
			}

			setTimeout(() => {
				setOpen(true);
				onModalOpen(true);
			}, 500);
		} catch (error) {
			console.error(error);
		}
	}

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	async function onLog(log: any): Promise<void> {
		try {
			await axiosPost("https://api.bond-card.com/log", {
				log,
			});
		} catch (error) {
			console.error(error);
		}
	}

	async function onDetailFetch(id?: string) {
		if (!id) {
			setState({
				error: true,
				isLoading: false,
				data: null,
			});
			return;
		}

		const { data, error, loaded, status } = isPageRequested
			? await axiosPost("https://api.bond-card.com/getPage", {
					pageId: id,
				})
			: await axiosGet(`https://api.bond-card.com/detail/${id}`);

		setState({
			error,
			isLoading: !loaded,
			data,
			status,
		});
	}

	// function onLinkClick(): void {
	//   window.open(link, "_blank");
	// }

	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	function createVCFDownloadLink(data: any): {
		link: string;
		filename: string;
	} {
		const headerItem = data.items.find((item) => item.type === "header");
		let filename = "bond-card";
		if (headerItem) {
			const firstName = headerItem.values.find(
				(val) => val.name === "firstname",
			)?.value;
			const lastName = headerItem.values.find(
				(val) => val.name === "lastname",
			)?.value;

			filename = `${firstName || ""}${firstName && lastName ? "_" : ""}${
				lastName ? lastName : ""
			}`;
		}

		const file = new Blob([data.vcf], { type: "text/vcard;charset=utf-8" });
		const link = window.URL.createObjectURL(file);
		// const link = document.createElement("a");
		// link.href = url;
		// link.target = "_blank";
		// link.setAttribute("download", `${filename}.vcf`);
		// document.body.appendChild(link);

		return { link, filename: `${filename}.vcf` };
	}
});

export default CardDetailUI;
