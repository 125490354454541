import { AxiosRequestConfig } from "axios";
import { APP_CONFIG } from "app/config";
// import { createUser } from "ui/organism/Dashboard/dashboardResource";
// import { User, UserCredentials, RegisterCredentials } from "ui/organism/User/state";
// import { createRegisteredUser } from "ui/organism/RegisteredUsers/RegisteredUsersResource";

const requestConfig: AxiosRequestConfig = {
  baseURL: APP_CONFIG.API.FIREBASE.BASE_URL, // TODO uprav BASE_URL na svoje API
  timeout: 30000,
};

// Nastavenie autorizacnych hlaviciek, mozno odmazat, ked nepouzivas vo svojich requestoch
// function setAuthorizationHeaders(token): AxiosRequestConfig {
//   return {
//     ...requestConfig,
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
// }

// export async function getUsers(): Promise<any> {
//   const response = await axios.get("/users", requestConfig);
//   return {
//     users: response.data.map((user) => createRegisteredUser(user)),
//   };
// }

// TODO endpoint v mojom API, uprav na svoje, testovaci ucet: hnt@centrum.sk, pass: 123456
// export async function login(
//   credentials: UserCredentials
// ): Promise<string | Error> {
//   const response = await axios.post("/login", credentials, requestConfig);
//   console.log("response data", response.data);

//   // TODO Error handling
//   if (response.data.general) {
//     return new Error(response.data.general);
//   }

//   return response.data.token;
// }

// export async function getUserDetail(token: string): Promise<User> {
//     const response = await axios.get("/user", setAuthorizationHeaders(token));
//     console.log(response);
//     return createUser(response.data);
// }

// export async function signup(credentials: RegisterCredentials): Promise<any> {
//   const response = await axios.post("/signup", credentials, requestConfig);
//   // TODO Error handling
//   return response.data;
// }

export const MOCK_DATA = {
  id: "1c031720-52af-4b83-bfc5-bcc430b87d71",
  userId: "9c4cd641-6f7e-43b8-9199-099d73ea28e0",
  title: "Osobní",
  picture: "",
  items: [
    {
      id: "Id",
      type: "header",
      picture: "",
      description: "Hlavička",
      values: [
        {
          name: "firstname",
          value: "Michal",
        },
        {
          name: "company",
          value: "",
        },
        {
          name: "lastname",
          value: "Simik",
        },
      ],
    },
    {
      id: "Id2",
      type: "phone",
      description: "Telefón",
      picture: "",
      values: [
        {
          name: "phone",
          value: "095544222",
        },
      ],
    },
    {
      id: "Id44",
      type: "note",
      description: "Note",
      picture: "",
      values: [
        {
          name: "note",
          value: "Super poznamka ktora zaujme",
        },
      ],
    },
    {
      id: "Id3",
      type: "email",
      description: "E-mail",
      picture: "",
      values: [
        {
          name: "email",
          value: "email@email.com",
        },
      ],
    },
    {
      id: "Id3",
      type: "address",
      picture: "",
      description: "Adresa",
      values: [
        {
          name: "country",
          value: "Česká republika",
        },
        {
          name: "description",
          value: "Popis",
        },
        {
          name: "zip",
          value: "06701",
        },
        {
          name: "street",
          value: "Obecná 22",
        },
        {
          name: "city",
          value: "Zlín",
        },
      ],
    },
    {
      id: "Id3",
      type: "link",
      description: "Link",
      picture: "",
      values: [
        {
          name: "link",
          value: "https://bondapp.com",
        },
      ],
    },
  ],
  timestamp: "2022-08-13T17:07:24.674147Z",
};

export function valueParser(
  values: Array<{ name: string; value: string }>
): any | null {
  return values.reduce(
    (output, currentItem) => ({
      ...output,
      [currentItem.name]: currentItem.value.length ? currentItem.value : null,
    }),
    {}
  );
}
