import classnames from "classnames";
import React from "react";
import Logo from "ui/atom/Logo";
import { LocalizationType } from "ui/organism/App/state";
import { TRANSLATIONS } from "locale/default";

export interface IDataProps {
  localization: LocalizationType;
}

export interface IExternalProps {
  className?: string;
}

type IProps = IDataProps & IExternalProps;

const Footer = React.memo(({ className, localization }: IProps) => {
  return (
    <div className={classnames("mol-footer", className)}>
      <Logo className="mol-footer__logo" type="text" />
      <div className="mol-footer__copyright">
        <div>Copyright &#169; Appgrade Studio s.r.o.</div>
        <div>{TRANSLATIONS.FOOTER.ALL_RIGHTS[localization]}</div>
      </div>
    </div>
  );
});

export default Footer;
