import axios from "axios";
import { useState, useEffect } from "react";

// export const useAxios = ({ url, method, body = "", headers = "" }) => {
//   const [response, setResponse] = useState(null);
//   const [error, setError] = useState('');
//   const [loading, setloading] = useState(true);
//
//   const fetchData = () => {
//     axios[method](url, JSON.parse(headers), JSON.parse(body))
//         .then((res) => {
//           setResponse(res.data);
//         })
//         .catch((err) => {
//           setError(err);
//         })
//         .finally(() => {
//           setloading(false);
//         });
//   };
//
//   useEffect(() => {
//     fetchData();
//   }, [method, url, body, headers]);
//
//   return { response, error, loading };
// };

export const usePost = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");

  const post = (url: string, payload: any) => {
  (async () => {
    try {
      setLoading(loading)
      new Promise(res => setTimeout(res, 2000))
      const response = await axios.post(url, payload);
      setLoading(false)
      setData(response.data);
    } catch (error) {
      setLoading(false)
      const err: any = error;
      setError(err.message);
    } finally {
      setLoading(false)
    }
  })();
};

  return { data, error, loading, post };
}

// https://blog.openreplay.com/integrating-axios-with-react-hooks/
export const useAxiosPost = (url: string, payload: any) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.post(url, payload);

        setData(response.data);
      } catch (error) {
        const err: any = error;
        setError(err.message);
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return { data, error, loaded };
};

export async function axiosPost(url: string, payload: any) {
  let data = null;
  let error = false;
  let loaded = false;
  let status = 200;

  try {
    const response = await axios.post(url, payload);
    data = response.data;
    status = response.status;

  } catch (apiError) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const err: any = apiError;
    // error = err.message;
    error = true;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    status = (apiError as any)?.response?.status;

  } finally {
    loaded = true;
  }

  return { data, error, loaded, status };
}

export async function axiosGet(url: string) {
  let data = null;
  let error = false;
  let loaded = false;
  let status = 200;

  try {
    const response = await axios.get(url);
    data = response.data;
    status = response.status;
  } catch (apiError) {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    const err: any = apiError;
    // error = err.message;
    error = true;
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    status = (apiError as any)?.response?.status;
  } finally {
    loaded = true;
  }

  return { data, error, loaded, status };
}
