import { createReducer } from "redux-create-reducer";
import Action from "./action";
import { DEFAULT_STATE, IState, LocalizationType } from "./state";

export default createReducer<IState, any>(DEFAULT_STATE, {
  [Action.INIT_APP_VERSION](
    state: IState,
    version: { payload: string }
  ): IState {
    return {
      ...state,
      appVersion: version.payload,
    };
  },

  [Action.SET_LOCALIZATION](
    state: IState,
    localization: { payload: LocalizationType }
  ): IState {
    return {
      ...state,
      localization: localization.payload,
    };
  },

  [Action.SET_UID](
    state: IState,
    uid: { payload: string }
  ): IState {
    return {
      ...state,
      uid: uid.payload,
    };
  },
});
