// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export const ANALYTICS_EVENTS = {
    CARD_DETAIL: "web_card_detail"
}

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAPgtgum_0dAnXT1hrDjCHza4XejtfUL1o",
  authDomain: "cards-a2de4.firebaseapp.com",
  projectId: "cards-a2de4",
  storageBucket: "cards-a2de4.appspot.com",
  messagingSenderId: "412477978322",
  appId: "1:412477978322:web:5dd75519974193bb68df0b",
  measurementId: "G-CCTVGRWHJK",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export default analytics;
