import React, {
	createContext,
	PropsWithChildren,
	useCallback,
	useState,
} from "react";
import { ToastContainer, IToast } from ".";

export const ToastContext = createContext<{
	toast: IToast | undefined;
	show: ({ message }: IToast) => void;
	hide: () => void;
}>({
	toast: { message: "" },
	show: () => null,
	hide: () => null,
});

export const ToastContextProvider: React.FC<PropsWithChildren> = ({
	children,
}) => {
	const [toast, setToast] = useState<IToast>();

	const show = useCallback(({ message }: IToast) => {
		setToast({ message });
	}, []);

	const hide = useCallback(() => {
		setToast(undefined);
	}, []);

	return (
		<ToastContext.Provider value={{ toast, show, hide }}>
			<ToastContainer />
			{children}
		</ToastContext.Provider>
	);
};
