import classnames from "classnames";
import React from "react";

interface IProps {
  className?: string;
  children: React.ReactNode;
  href?: null | string;
  type: string;
}

const Card = React.memo(({ className, children, href, type }: IProps) => {
  return (
    <>
      {href ? (
        <a
          className={className}
          href={href}
          target={type !== "email" && type !== "phone" ? "_blank" : undefined}
          rel={type !== "email" && type !== "phone" ? "noreferrer" : undefined}
        >
          <div className={classnames("atm-card", className)}>{children}</div>
        </a>
      ) : (
        <div className={classnames("atm-card", className)}>{children}</div>
      )}
    </>
  );
});

export default Card;
