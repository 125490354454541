import classnames from "classnames";
import React from "react";
import ContentWrapper from "ui/atom/ContentWrapper";
import PageTitle from "ui/atom/PageTitle";
import Footer from "ui/molecule/footer/Footer";
import Header from "ui/molecule/header/Header";
import { TRANSLATIONS } from "locale/default";
import type { LocalizationType } from "ui/organism/App/state";

export interface IDataProps {
	localization: LocalizationType;
}

export interface IExternalProps {
	className?: string;
}

type IProps = IDataProps & IExternalProps;

const DEFAULT_BUTTON_COLOR = "#000000";

const NotActivatedPage = React.memo(({ className, localization }: IProps) => {
	return (
		<ContentWrapper
			className="mol-error-page__content-offset"
			withHeader={true}
		>
			<Header />
			<div>
				<PageTitle
					label={TRANSLATIONS.NOT_ACTIVATED_PAGE.LABEL[localization]}
				/>
				<p className="text-center mb-0 mt-4">
					{TRANSLATIONS.NOT_ACTIVATED_PAGE.DESCRIPTION[localization]}
				</p>
				<div className={classnames("atm-save-contact-button", className)}>
					<div
						className="atm-save-contact-button__action"
						style={{ background: DEFAULT_BUTTON_COLOR }}
					>
						<a
							className="atm-save-contact-button__action-link"
							href="/get-app"
							target="_blank"
							rel="noreferrer"
						>
							{TRANSLATIONS.NOT_ACTIVATED_PAGE.BUTTON[localization]}
						</a>
					</div>
				</div>
			</div>
			<Footer />
		</ContentWrapper>
	);
});

export default NotActivatedPage;
