export const NAMESPACE = "cardDetail";

export interface IState {
  data: null | any;
  isLoading: boolean;
  error: null | Error;
}

export const DEFAULT_STATE: IState = {
  data: null,
  isLoading: false,
  error: null,
};
