import React from "react";
import { LocalizationType } from "ui/organism/App/state";

interface IProps {
  className?: string;
  localization: LocalizationType;
  onLocalizationChange(localization: LocalizationType): void;
}

const LanguageSelect = React.memo(
  ({ className, localization, onLocalizationChange }: IProps) => {
    // const [value, setValue] = React.useState("cz");

    return (
      <div className={className}>
        {/* <span className={`fi fi-${value} atm-language-select__flag-icon`} /> */}
        <select
          className="mol-select"
          id="lang-select"
          name="lang-select"
          value={localization}
          onChange={onValueChange}
        >
          <option value="sk">SK</option>
          <option value="cz">CZ</option>
          <option value="en">EN</option>
        </select>
      </div>
    );

    function onValueChange(event: React.ChangeEvent<HTMLSelectElement>) {
      // setValue(event.target.value);
      onLocalizationChange(event.target.value as LocalizationType);
    }
  }
);

export default LanguageSelect;
