export function isFacebookApp(): boolean {
  var ua = navigator.userAgent;
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv)',
  ];
  const regex = new RegExp(`(${rules.join('|')})`, 'ig');
  if (!ua) {
    console.error("UA not detected");
    return false;
  }

  return (
    ua.indexOf("FBAN") > -1 ||
    ua.indexOf("FBAV") > -1 ||
    ua.indexOf("Instagram") > -1 ||
    Boolean(ua.match(regex))
  );
}
