import classnames from "classnames";
import React, { useEffect } from "react";
import Icon from "./Icon";
import DelayRender from "./DelayRender";
// import testLoading from "../../assets/testLoading.svg";

interface IProps {
  className?: string;
}

const Loader = React.memo(({ className }: IProps) => {
  return (
    <DelayRender waitBeforeShow={200}>
      <div className={classnames("atm-loader", className)}>
        <div className="atm-loader__wrapper">
          <Icon icon="loading" />
        </div>
      </div>
    </DelayRender>
  );
});

export default Loader;
