import classnames from "classnames";
import React from "react";
import ContentWrapper from "ui/atom/ContentWrapper";
import PageTitle from "ui/atom/PageTitle";
import Footer from "ui/molecule/footer/Footer";
import Header from "ui/molecule/header/Header";
import { TRANSLATIONS } from "locale/default";
import { LocalizationType } from "ui/organism/App/state";

export interface IDataProps {
  localization: LocalizationType;
}

export interface IExternalProps {
  className?: string;
}

type IProps = IDataProps & IExternalProps;

const ErrorPage = React.memo(({ className, localization }: IProps) => {
  return (
    <ContentWrapper
      className="mol-error-page__content-offset"
      withHeader={true}
    >
      <Header />
      <PageTitle label={TRANSLATIONS.BLANK_PAGE.LABEL[localization]} />
      <Footer />
    </ContentWrapper>
  );
});

export default ErrorPage;
