import React, { useCallback } from "react";

import { TRANSLATIONS } from "locale/default";
import { useToast } from "../toast";
import { useSelector } from "react-redux";

export const Text = ({ value }) => {
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	const { localization } = useSelector((state) => (state as any)?.application);
	const { show } = useToast();

	const handleClick = useCallback(() => {
		navigator.clipboard.writeText(value);
		show({
			message:
				TRANSLATIONS.GENERAL.TOAST.COPIED_TO_CLIPBOARD.MESSAGE[localization],
		});
	}, [value, show, localization]);

	return (
		<button type="button" className="atm-detail-text" onClick={handleClick}>
			{value}
		</button>
	);
};
