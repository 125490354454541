import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import ContentWrapper from "ui/atom/ContentWrapper";
import PageTitle from "ui/atom/PageTitle";
import { LocalizationType } from "ui/organism/App/state";
import ErrorPage from "ui/molecule/errorPage/ErrorPage";
import Footer from "ui/molecule/footer/Footer";
import Header from "ui/molecule/header/HeaderUI";
import ResetPasswordForm from "../ResetPasswordFormUI";
import { TRANSLATIONS } from "locale/default";

export interface IDataProps {
  localization: LocalizationType;
}

export interface ICallbackProps {
  onLocalizationChange(localization: LocalizationType): void;
}

export interface IExternalProps {
  className?: string;
}

type IProps = IDataProps & ICallbackProps & IExternalProps;

// etc. http://localhost:3000/reset?email=hnt@centrum.sk&code=sdsadadad
const ResetPassword = React.memo(
  ({ className, localization, onLocalizationChange }: IProps) => {
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email");
    const code = searchParams.get("code");

    if (!email || !code || !email.length || !code.length) {
      return <ErrorPage />;
    }

    return (
      <ContentWrapper
        withHeader={true}
        className="mol-error-page__content-offset"
      >
        <Header
          localization={localization}
          onLocalizationChange={onLocalizationChange}
        />
        <div className="mol-reset-password">
          <div className="mol-reset-password__text">
            <PageTitle
              label={TRANSLATIONS.RESET_PASSWORD.LABEL[localization]}
            />
            <div>{email}</div>
          </div>
          <ResetPasswordForm
            localization={localization}
            email={email}
            code={code}
          />
        </div>
        <Footer />
      </ContentWrapper>
    );
  }
);

export default ResetPassword;
