import React from "react";

interface IProps {
  waitBeforeShow: number; // ms
  children: React.ReactNode;
}

// Component to delay render cause safari calculation issue
class Delayed extends React.Component<IProps> {
  public state = { hidden: true };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ hidden: false });
    }, this.props.waitBeforeShow);
  }

  render(): React.ReactNode {
    return this.state.hidden ? null : this.props.children;
  }
}

export default Delayed;
