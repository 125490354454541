import classnames from "classnames";
import React from "react";

interface IProps {
  className?: string;
  withHeader?: boolean;
  children: React.ReactNode;
}

const ContentWrapper = React.memo(({ className, children, withHeader }: IProps) => {
  return (
    <div className={classnames("atm-content-wrapper", withHeader && "atm-content-wrapper--with-header", className)}>
      {children}
    </div>
  );
});

export default ContentWrapper;
