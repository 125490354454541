import classnames from "classnames";
import React from "react";
import icDetailBond from "../../assets/icDetailBond.png";

interface IProps {
	className?: string;
}

const DetailBondLogo = React.memo(({ className }: IProps) => {
	return (
		<a
			className={classnames(className)}
			target="_blank"
			rel="noreferrer"
			href="/get-app"
		>
			<img
				className="atm-detail-bond-logo__image"
				src={icDetailBond}
				alt="Bond logo"
			/>
		</a>
	);
});

export default DetailBondLogo;
