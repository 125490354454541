import React, { useCallback, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import ErrorPage from "ui/molecule/errorPage/ErrorPage";
import ResetPassword from "ui/molecule/resetPassword/ResetPassword";
import CardDetail from "../CardDetail/CardDetail";
import HomePage from "../Home/Home";
import { LocalizationType } from "./state";
// import Linkredirector from "ui/atom/LinkRedirector";

// Props received from selectors
export interface IDataProps {
	applicationVersion: string;
	localization: LocalizationType;
	uid: null | string;
}

// Props received from dispatchers
export interface ICallbackProps {}

// External props passed from parent component
export interface IExternalProps {
	// store: Store;
	// history: History;
}

type Props = IDataProps & ICallbackProps & IExternalProps;

export const AppUI = ({ localization, uid }: Props) => {
	// fix loader centering onSafari browser 100vh not working as expected
	// const targetRef = React.useRef<HTMLDivElement>(null);
	// let scrollListenerAdded = false;

	let listenerAdded = false;
	const appHeight = () => {
		const doc = document.documentElement;
		doc.style.setProperty("--app-height", `${window.innerHeight}px`);
	};

	// const onScroll = useCallback((event: Event) => {
	//   event.preventDefault();
	//   event.stopPropagation();
	//   window.scroll(0, 0);
	//   console.log("scroll 1", event);
	// }, []);

	useEffect(() => {
		document.title = localization === "sk" ? "Digitálna vizitka" : "Bond";
		// let modalCheckInterval: null | NodeJS.Timer = null;
		if (!listenerAdded) {
			window.addEventListener("resize", appHeight);
			appHeight();
			listenerAdded = true;

			// modalCheckInterval = setInterval(() => {
			//   const modalElement = document.querySelector(".modal-backdrop");
			//   if (modalElement) {
			//     window.addEventListener("scroll", onScroll);
			//     scrollListenerAdded = true;
			//     console.log("modal opened");
			//   } else {
			//     window.removeEventListener("scroll", onScroll);
			//     scrollListenerAdded = false;
			//     console.log("modal closed");
			//   }
			// }, 500);
		}

		return () => {
			window.removeEventListener("resize", appHeight);
			// if (modalCheckInterval) {
			//   clearInterval(modalCheckInterval);
			// }
			// if (scrollListenerAdded) {
			//   window.removeEventListener("scroll", onScroll);
			// }
		};
	}, [localization]);

	return (
		<Routes>
			{Array.from(Array(100)).map((_, index) => (
				<Route
					key={`p${index}`}
					path={`/p${index}/:id`}
					element={
						<div className="ogm-app">
							<CardDetail uid={uid} />
						</div>
					}
				/>
			))}

			{Array.from(Array(100)).map((_, index) => (
				<Route
					key={`t${index}`}
					path={`/t${index}/:id`}
					element={
						<div className="ogm-app">
							<CardDetail uid={uid} />
						</div>
					}
				/>
			))}

			{Array.from(Array(100)).map((_, index) => (
				<Route
					key={`c${index}`}
					path={`/c${index}/:id`}
					element={
						<div className="ogm-app">
							<CardDetail uid={uid} />
						</div>
					}
				/>
			))}

			{Array.from(Array(1000)).map((_, index) => (
				<Route
					key={`szn${index}`}
					path={`/szn${index}/:id`}
					element={
						<div className="ogm-app">
							<CardDetail uid={uid} />
						</div>
					}
				/>
			))}
			{Array.from(Array(1000)).map((_, index) => (
				<Route
					key={`seznam${index}`}
					path={`/seznam${index}/:id`}
					element={
						<div className="ogm-app">
							<CardDetail uid={uid} />
						</div>
					}
				/>
			))}

			<Route
				path="/seznam/:id"
				element={
					<div className="ogm-app">
						<CardDetail uid={uid} />
					</div>
				}
			/>
			<Route
				path="/szn/:id"
				element={
					<div className="ogm-app">
						<CardDetail uid={uid} />
					</div>
				}
			/>
			<Route
				path="/d/:id"
				element={
					<div className="ogm-app">
						<CardDetail uid={uid} />
					</div>
				}
			/>
			<Route
				path="/:id"
				element={
					<div className="ogm-app">
						<CardDetail uid={uid} />
					</div>
				}
			/>
			<Route
				path="/reset"
				element={
					<div className="ogm-app">
						<ResetPassword />
					</div>
				}
			/>
			{/* <Route path="/download" element={<Linkredirector redirectUrl=""/>} /> */}
			<Route path="/" element={<HomePage />} />
			<Route path="/get-app" element={<HomePage />} />
			<Route path="/download" element={<HomePage />} />
			<Route
				path="*"
				element={
					<div className="ogm-app">
						<ErrorPage />
					</div>
				}
			/>
		</Routes>
	);
};
