const TEST_KEY = "__storage_test__";

export function isSupportedFeature(storageType: string): boolean {
    if (!window) {
        return false;
    }

    let storage;

    try {
        storage = window[storageType];
        storage.setItem(TEST_KEY, TEST_KEY);
        storage.removeItem(TEST_KEY);

        return true;
    } catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === "QuotaExceededError" ||
            // Firefox
            e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}
