export const NAMESPACE = "application";

export enum Localization {
  EN = "en",
  CZ = "cz",
  SK = "sk",
  UNKNOWN = "",
}

export type LocalizationType =
  | Localization.EN
  | Localization.CZ
  | Localization.SK
  | Localization.UNKNOWN;

export interface ICookie {
  [cookieName: string]: string;
}

export interface IState {
  // TODO add appstate
  // cookies: ICookie,
  appVersion: string;
  localization: LocalizationType;
  uid: null | string;
}

export const DEFAULT_STATE: IState = {
  // cookies: {
  //     "testCookie": "123456",
  // }
  appVersion: "1.0",
  localization: Localization.UNKNOWN,
  uid: null,
};
