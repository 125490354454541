import classnames from "classnames";
import React, { useEffect } from "react";
import validator from "validator";
import ActionButton from "ui/atom/ActionButton";
import PasswordField from "ui/atom/PasswordField";
import Icon from "ui/atom/Icon";
import { LocalizationType } from "ui/organism/App/state";
import { TRANSLATIONS } from "locale/default";
import { axiosPost, useAxiosPost } from "utils/axiosPostHook";

interface IProps {
  localization: LocalizationType;
  email: string;
  code: string;
  // onPassUpdate(newPass: string): void;
}

const ResetPasswordForm = React.memo(
  ({ localization, email, code }: IProps) => {
    const [pass, setPass] = React.useState("");
    const [passAgain, setPassAgain] = React.useState("");
    const [formError, setError] = React.useState<null | string>(null);
    const [changedSuccess, setChangedSuccess] = React.useState<boolean>(false);

    useEffect(() => {
      if (!formError) {
        return;
      }

      for (const error of [
        TRANSLATIONS.RESET_PASSWORD.DONT_MATCH,
        TRANSLATIONS.RESET_PASSWORD.UPDATE_FAILED,
        TRANSLATIONS.RESET_PASSWORD.MIN_LENGTH,
      ]) {
        if (updateErrorLocale(error)) {
          break;
        }
      }
    }, [localization]);

    const updateErrorLocale = (locale: { [key: string]: string }): boolean => {
      if (Object.values(locale).filter((value) => value === formError)) {
        setError(locale[localization]);
        return true;
      }

      return false;
    };

    const onPassUpdate = async (pass: string) => {
      const { data, error, loaded } = await axiosPost(
        "https://api.bond-card.com/newPassword",
        {
          email,
          password: pass,
          code,
        }
      );

      if (!loaded || error) {
        setError(TRANSLATIONS.RESET_PASSWORD.UPDATE_FAILED[localization]);
      }

      if (!error) {
        setChangedSuccess(true);
      }
    };

    return (
      <div>
        {changedSuccess ? (
          <div className="mol-reset-password-form__success">
            <Icon icon="success" />
            <div>{TRANSLATIONS.RESET_PASSWORD.UPDATE_DONE[localization]}</div>
          </div>
        ) : (
          <form>
            <PasswordField
              className="mol-reset-password-form__field"
              id="pass"
              placeholder={TRANSLATIONS.RESET_PASSWORD.NEW[localization]}
              value={pass}
              onChange={onSetPass}
              error={null}
            />
            <PasswordField
              className={classnames(
                "mol-reset-password-form__field",
                !formError && "mol-reset-password-form__field--last"
              )}
              id="pass-again"
              placeholder={TRANSLATIONS.RESET_PASSWORD.NEW_AGAIN[localization]}
              value={passAgain}
              onChange={onSetPassAgain}
              error={null}
            />
            {formError && (
              <div className="mol-reset-password-form__error">{formError}</div>
            )}
            <ActionButton
              disabled={!!(!pass.length || !passAgain.length || formError)}
              label={TRANSLATIONS.RESET_PASSWORD.UPDATE[localization]}
              onClick={onSubmit}
            />
          </form>
        )}
      </div>
    );

    function onSetPass(value: string) {
      if (validate(value)) {
        setError(null);
      } else {
        setError(TRANSLATIONS.RESET_PASSWORD.MIN_LENGTH[localization]);
      }

      setPass(value);
    }

    function onSetPassAgain(value: string): void {
      if (!validate(value)) {
        setError(TRANSLATIONS.RESET_PASSWORD.MIN_LENGTH[localization]);
      } else if (pass !== value) {
        setError(TRANSLATIONS.RESET_PASSWORD.DONT_MATCH[localization]);
      } else {
        setError(null);
      }

      setPassAgain(value);
    }

    function validate(value: string): boolean {
      return validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 0,
        minUppercase: 0,
        minNumbers: 0,
        minSymbols: 0,
      });
    }

    function onSubmit(): void {
      if (!formError) {
        onPassUpdate(pass.trim());
      }
    }
  }
);

export default ResetPasswordForm;
