import { combineReducers} from "redux";
import applicationReducer from "ui/organism/App/reducer";
import { IState as AppState, NAMESPACE as APP_NAMESPACE} from "ui/organism/App/state";

export interface State {
    [APP_NAMESPACE]: AppState;
}

export const rootReducer = combineReducers<State>({
    [APP_NAMESPACE]: applicationReducer,
    // TODO: add another reducers
});