import React, { useEffect, useRef } from "react";
import { useToast, Toast } from ".";

const VISIBLE_FOR = 2000;

export const ToastContainer = () => {
	const { toast, hide } = useToast();
	const timeout = useRef({});

	useEffect(() => {
		if (toast) {
			timeout.current = setTimeout(hide, VISIBLE_FOR);
			return () => {
				if (timeout.current) {
					clearTimeout(timeout.current as NodeJS.Timeout);
				}
			};
		}
	}, [hide, toast]);

	if (!toast) {
		return null;
	}

	return <Toast />;
};
