import classNames from "classnames";
import React from "react";
import { TRANSLATIONS } from "locale/default";
import { LocalizationType } from "ui/organism/App/state";
import UserAvatarUI from "ui/atom/UserAvatarUI";
import icDetailBond from "../../../assets/icDetailBond.png";
import { APP_STORE_URL } from "ui/organism/Home/HomeUI";
import { isFacebookApp } from "utils/fbBroserDetector";
import { useLocation, useSearchParams } from "react-router-dom";
import UserName from "../../atom/UserName";
import UserBio from "../../atom/UserBio";
import UserPosition from "../../atom/UserPosition";
import CoverPhoto from "../../atom/CoverPhoto";
import DetailBondLogo from "../../atom/DetailBondLogo";
import SaveContactButton from "../../atom/SaveContactButton";

export interface IExternalProps {
  className?: string;
  isBondLogoHidden?: boolean;
  isSaveButtonHidden?: boolean;
  localization: LocalizationType;
  tintColor: string;
  file: {
    url: string;
    name: string;
  };
  user: {
    imageUrl?: string;
    lastName?: string;
    firstName?: string;
    bio?: string;
    position?: string;
    company?: string;
    logo?: string;
    hasProBadge?: boolean;
    coverPhoto?: string;
  };
  onItemSave(): Promise<void>;
}

type IProps = IExternalProps;

const DetailHeader = React.memo(
  ({ className, localization, tintColor, user, file, onItemSave, isBondLogoHidden, isSaveButtonHidden }: IProps) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const platform = searchParams.get("platform");
    const isInAppBrowser = isFacebookApp() && (!platform || platform !== "bond");

    return (
      <div
        className={classNames(
          "ogm-card-detail__header",
          className
        )}
      >
        {user.coverPhoto &&
          <div className="ogm-card-detail__cover-wrapper">
            <CoverPhoto coverPhoto={user.coverPhoto} layout="standard" />
          </div>
        }
        {!isBondLogoHidden && (
            <DetailBondLogo className="ogm-card-detail__app-link" />
        )}
        <UserAvatarUI
          className="ogm-app__user ogm-card-detail__avatar--offset"
          imageUrl={user.imageUrl}
          companyUrl={user.logo}
        />
        <div className="ogm-card-detail__user-info">
          <UserName className="ogm-card-detail__username" firstName={user.firstName} lastName={user.lastName} hasProBadge={user.hasProBadge} />
          <div className="ogm-card-detail__position">
            {((user.position && user.position.length > 0) || (user.company && user.company.length > 0)) &&
                <UserPosition position={user.position} company={user.company} />
            }
            {user.bio && user.bio.length > 0 &&
                <UserBio bio={user.bio} />
            }
          </div>
        </div>
        {!isInAppBrowser && !isSaveButtonHidden && file.url ? (
          <SaveContactButton onItemSave={onItemSave} localization={localization} tintColor={tintColor} file={file} />
          ) : (<div className="ogm-card-detail__header__save-hidden"/>
        )}
      </div>
    );
  }
);

export default DetailHeader;
