import { call, all, fork, put } from "redux-saga/effects";
import { createWatchDaemon } from "sagas/sagaHelpers";
import { isSupportedFeature } from "utils/localStorage";
import Action from "./action";
import { Localization, LocalizationType } from "./state";
import Cookies from "js-cookie";
// import * as moment from 'moment';
// import { autologinUser } from "ui/organism/User/sagas";

const USER_LOCALIZATION_LANGUAGE = "localization";

function* rootSaga() {
  yield all([fork(loadLocalizationSettings)]);
  yield all([fork(loadCookiesSettings)]);
}

// TODO move to Dashboard Sagas and save to Redux
// function* loadInitDataAtClient() {
// 	try {
// 		// TODO get verification token and verify it
// 		yield call(autologinUser);
// 	} catch(apiError) {
// 		console.error(apiError);
// 	}
// }

function* loadCookiesSettings() {
  if (!window || !document.cookie) {
    return;
  }

  const existedCookie = Cookies.get('uid');

  const uniqueId = () => {
    const dateString = Date.now().toString(36);
    const randomness = Math.random().toString(36).substr(2);
    return dateString + randomness;
  };

  const cookieValue = existedCookie || uniqueId();
  if (!existedCookie) {
    const result = Cookies.set("uid", cookieValue, {expires: 1}); // max age 1 year
    const expiration = result?.split(";").find((row) => row.startsWith(" expires="))
    ?.split("=")[1];
    console.log("expiration", expiration);
    if (expiration) {
      Cookies.set("uid_expiration", `${Date.parse(expiration)}`, {expires: 365}); // max age 1 year
    }
    console.log(result);
    
  } else {
    // check expiration
    let expirationCookie = Cookies.get('uid_expiration');
    if (expirationCookie) {
      // TODO check expiracie, ak je menej ako 2 dni refresh cookie
      // const uidExpiration = parseInt(expirationCookie, 10);
      // let now = moment().format('LLLL');
    }
  }
  
  yield put(Action.setUID(cookieValue));
}

function* loadLocalizationSettings() {
  if (!window || !isSupportedFeature("localStorage")) {
    return;
  }

  const storage = window["localStorage"];
  let selectedLang;
  if (!storage.getItem(USER_LOCALIZATION_LANGUAGE)) {
    const languages = window.navigator.languages || window.navigator.language;
    if (!languages || !languages.length) {
      return;
    }

    const browserLanguage = languages[0].toLowerCase();
    const selectedLang = getSupportedLanguage(browserLanguage);
    yield put(Action.setLocalization(selectedLang));
    return;
  }

  selectedLang = storage.getItem(USER_LOCALIZATION_LANGUAGE);
  yield put(Action.setLocalization(selectedLang || Localization.EN));
}

function getSupportedLanguage(language: string): LocalizationType {
  switch (true) {
    case language.includes(Localization.SK):
      return Localization.SK;
    case language.includes(Localization.EN):
      return Localization.EN;
    case language.includes(Localization.CZ):
      return Localization.CZ;
    default:
      return Localization.EN;
  }
}

function* setUserLocalization({
  payload: language,
}: {
  payload: LocalizationType;
}) {
  if (isSupportedFeature("localStorage")) {
    const storage = window["localStorage"];
    storage.setItem(USER_LOCALIZATION_LANGUAGE, language);

    yield put(Action.setLocalization(language));
  }
}

// Running at server
export const init: Array<() => any> = [];
// Running at client
export const daemons: Array<() => any> = [
  rootSaga,
  createWatchDaemon(Action.SET_LOCAL_STORAGE_LOCALIZATION, setUserLocalization),
];
