import classnames from "classnames";
import React, { useCallback, useState } from "react";
import { valueParser } from "ui/organism/App/Apiclient";
import AddressItem from "./AddressItem";
import Card from "./Card";
import Icon, { AVAILABLE_ICONS, type ICON_TYPE } from "./Icon";
import { axiosPost } from "utils/axiosPostHook";
import type { UAParserInstance } from "ua-parser-js";
import type { ValuesItem, ItemValueName } from "types";
import { Text } from "./item";

const itemValueByName = (
	valueName: ItemValueName,
	itemValues: ValuesItem[],
) => {
	return itemValues?.find((value) => value.name === valueName)?.value;
};

interface IProps {
	index: number;
	detailId?: string;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	item: any; // TODO
	color: string;
	uid: null | string;
	UAParser: null | UAParserInstance;
	itemClicked: {
		is: boolean;
		set: (value: boolean) => void;
	};
}

const DetailItem = React.memo(
	({ index, item, color, detailId, UAParser, uid, itemClicked }: IProps) => {
		const { type } = item;
		const isAddress = type === "address";
		let value = !isAddress
			? item.values[0].value
			: item.values.map((val: { name: string; value: string }) => val.value);
		const isSocialItem = type === "social";
		value =
			(isSocialItem &&
				item.values.find(
					(item) => item.name === "username" && item.value && item.value.length,
				)?.value) ||
			value;
		const href = !isAddress
			? isSocialItem
				? getHrefForValue(
						type,
						item.values.find((value) => value.name === "link")?.value,
					)
				: getHrefForValue(type, value, item)
			: undefined;
		const address = href ? undefined : valueParser(item.values);
		const addressLink = isAddress
			? getAddressLink(address.street, address.city, address.country)
			: undefined;
		const socialIcon = isSocialItem
			? item.values.find((value) => value.name === "social")
			: null;
		let itemIcon = socialIcon?.value || type;
		itemIcon = Object.values(AVAILABLE_ICONS).includes(itemIcon)
			? itemIcon
			: "link";
		// itemIcon = "https://firebasestorage.googleapis.com/v0/b/cards-a2de4.appspot.com/o/page-pics%2FT3UOp5hSR.png?alt=media&token=page-pics%2FT3UOp5hSR.png";
		const isSimpleDesign = item.design === "simple";

		async function onItemClick() {
			try {
				await axiosPost("https://api.bond-card.com/log", {
					log: {
						pageId: detailId,
						deviceId: `${uid}`,
						pageItemId: `${item.id}`,
						type: !itemClicked.is ? "firstClick" : "click",
					},
				});

				itemClicked.set(true);
			} catch (error) {
				console.error(error);
			}
		}

		if (type === "textHeader") {
			return (
				<h2
					className={classnames(
						"atm-detail-text-header",
						index === 1 ? "first" : "",
					)}
				>
					{value}
				</h2>
			);
		}

		if (type === "text") {
			return <Text value={value} />;
		}

		if (type === "image") {
			const imageEl = <img alt={item.description} src={item.picture} />;

			return (
				<div className="atm-detail-image">
					{value && href ? (
						<a href={href} target="_blank" rel="noreferrer" className="d-block">
							{imageEl}
						</a>
					) : (
						imageEl
					)}
				</div>
			);
		}

		if (type === "spacer") {
			return (
				<div
					className={classnames(
						"atm-detail-spacer",
						index === 1 ? "first" : "",
					)}
					style={{ height: `${itemValueByName("height", item.values)}px` }}
				/>
			);
		}

		if (type === "youTubeVideo") {
			return (
				<div className="atm-detail-video">
					<iframe
						src={itemValueByName("playLink", item.values)}
						allowFullScreen={false}
						title="Embeded youtube video"
						width="100%"
						height="100%"
					/>
				</div>
			);
		}

		return (
			<Card
				className="atm-detail-item--offset atm-detail-item__value"
				type={type}
				href={href || addressLink}
			>
				{/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
				<div className="atm-detail-item" onClick={onItemClick}>
					<div
						className={classnames(
							"atm-detail-item__icon-wrapper",
							item.description.length === 0 &&
								"atm-detail-item__icon-wrapper--no-description",
						)}
						style={{ color }}
					>
						{item.picture?.length ? (
							<img
								className="atm-detail-item__icon--image"
								src={item.picture}
								alt="image-icon"
							/>
						) : (
							<Icon icon={itemIcon} />
						)}
					</div>
					{!item.picture ||
						(!item.picture.length && (
							<div className="atm-detail-item__icon--overlay">
								<Icon icon={`${itemIcon}_overlay` as ICON_TYPE} />
							</div>
						))}
					<div
						className={classnames(isSimpleDesign && "atm-detail-item--simple")}
					>
						{item.description.length > 0 && (
							<div
								className={classnames(
									"atm-detail-item__name",
									isSimpleDesign && "atm-detail-item__name--simple",
								)}
							>
								{item.description}
							</div>
						)}
						{href ? (
							!isSimpleDesign ? (
								<div className="atm-detail-item__value">
									{decodeURI(removeHttp(value))}
								</div>
							) : (
								""
							)
						) : !isAddress ? (
							<div className="atm-detail-item__value atm-detail-item__value-pre-wrap">
								{value}
							</div>
						) : !isSimpleDesign ? (
							<AddressItem
								zip={address.zip}
								city={address.city}
								country={address.country}
								street={address.street}
								state={address.state}
							/>
						) : (
							""
						)}
					</div>
				</div>
			</Card>
		);
	},
);

function addHttp(url: string): string {
	return !/^https?:\/\//i.test(url) ? `https://${url}` : url;
}

function removeHttp(url: string): string {
	return url.replace(/^https?:\/\//, "");
}

function getHrefForValue(
	type: string,
	value: string,
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	item?: any,
): null | string {
	switch (type) {
		case "email": {
			const subject = itemValueByName("subject", item?.values);
			const body = encodeURIComponent(
				itemValueByName("body", item?.values) || "",
			);
			return `mailto:${value}?subject=${subject}&body=${body}`;
		}
		case "phone":
			return `tel:${value}`;
		case "socialProfile":
		case "social":
		case "link":
		case "image":
			return addHttp(value);
		default:
			return null;
	}
}

function getAddressLink(street, city, country): string | null {
	// https://www.google.com/maps/search/?api=1&query=hlinky+44+brno
	const streetName = street?.replace(" ", "+");
	const cityName = city?.replace(" ", "+");
	const countryName = country?.replace(" ", "+");
	if (!streetName) {
		return null;
	}
	return `https://www.google.com/maps/search/?api=1&query=${[
		streetName,
		cityName,
		countryName,
	]
		.filter((item) => item)
		.join("+")}`;
}

export default DetailItem;
