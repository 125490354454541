import React from "react";

interface IProps {
  label: string;
}

const PageTitle = React.memo(({ label }: IProps) => {
  return <h2 className="atm-page-title">{label}</h2>;
});

export default PageTitle;
