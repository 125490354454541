import React from "react";
import ReactDOM from "react-dom/client";
import ClientRenderer from "./app/clientRenderer";
import "./styles/main.scss";

// const root = ReactDOM.createRoot(
//   document.getElementById("root") as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

try {
  const initialReduxStoreState = {};
  const renderer = new ClientRenderer(initialReduxStoreState);

  renderer.initialAppRender();

  // TODO: Here just try to render clientRenderer load clientStorage, init cookies and so on
} catch (error) {
  console.error("Failed to init application");
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
