import React, { useMemo } from "react";
import classnames from "classnames";
import UserCompanyLogo from "./UserCompanyLogo";
import placeholder from "../../assets/placeholder.jpg";

interface IProps {
	className?: string;
	imageUrl?: string;
	companyUrl?: string;
}

const UserAvatar = React.memo(({ className, imageUrl, companyUrl }: IProps) => {
	// const imgEl = React.useRef<HTMLImageElement>(null);
	const [loaded, setLoaded] = React.useState(false);
	// const [showPlaceholder, setShowPlaceholder] =
	//   React.useState<boolean>(false);

	// on image loaded sa zavola iba v pripae uspesneho nacitania obrazku
	// error image example "https://ik.imagekit.io/demo/img/non_existent_image.jpg"
	const onImageLoaded = () => {
		setLoaded(true);
	};

	// const imageNotFound = () => {
	//   setShowPlaceholder(true);
	// };

	// React.useEffect(() => {
	//   const imgElCurrent = imgEl.current;

	//   if (imgElCurrent) {
	//     imgElCurrent.addEventListener("load", onImageLoaded);
	//     imgElCurrent.addEventListener("error", imageNotFound);
	//     return () => {
	//       imgElCurrent.removeEventListener("load", onImageLoaded);
	//       imgElCurrent.removeEventListener("error", imageNotFound);
	//     };
	//   }
	// }, [imgEl]);

	const companyLogo = useMemo(() => {
		if (companyUrl) {
			return (
				<UserCompanyLogo
					className="atm-user-avatar__company"
					companyUrl={companyUrl}
				/>
			);
		}
		return null;
	}, [companyUrl]);

	return (
		<div className={classnames("atm-user-avatar", className)}>
			<div className="atm-user-avatar__image-wrapper">
				<img
					alt="avatar"
					className="atm-user-avatar__image"
					src={imageUrl || placeholder}
					style={{ display: "inline-block" }}
				/>
				{companyLogo}
			</div>
		</div>
	);
});

export default UserAvatar;
