import classNames from "classnames";
import React from "react";

interface IProps {
  label: string;
  onClick(): void;
  type?: "submit";
  disabled?: boolean;
  className?: string;
}

const ActionButton = React.memo(
  ({ disabled, label, className, type, onClick }: IProps) => {
    return (
      <button
        className={classNames("atm-action-btn", className)}
        type={type || "button"}
        onClick={onBtnClick}
        disabled={disabled}
      >
        {label}
      </button>
    );

    function onBtnClick() {
      onClick();
    }
  }
);

export default ActionButton;
